import { useEffect } from 'react';
import { ReceiveMessageType, SendMessageType } from 'src/@types/message';
import { TApiResponseData } from 'src/services/apiTypes';

declare global {
  interface DocumentEventMap {
    message: MessageEvent;
  }
}

type MessageHandler = (message: ReceiveMessageType | TApiResponseData<null>) => void;

// message 전송 함수 to React Native WebView
export const sendMessage = (message: SendMessageType) => {
  window.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

/**
 * React Native WebView 통신을 위한 커스텀 훅
 * - React Native WebView에서 postMessage로 보낸 메시지를 받아서 처리할 수 있습니다.
 * - sendMessage 함수를 통해 React Native WebView로 메시지를 보낼 수 있습니다.
 * @param handler 메시지를 받아서 처리할 함수
 */
const useWebViewCommunication = (handler?: MessageHandler) => {
  const isNativeApp = window.ReactNativeWebView !== undefined;

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        if (!event.data || typeof event.data !== 'string') return;
        const message: ReceiveMessageType = JSON.parse(event.data);
        handler && handler(message);
      } catch (error) {
        // do nothing
      }
    };

    document.addEventListener('message', handleMessage); // For Android
    window.addEventListener('message', handleMessage); // For iOS

    return () => {
      document.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, [handler]);

  return { sendMessage, isNativeApp };
};

export default useWebViewCommunication;
